import IMask from 'imask';
// import Swiper JS
import Swiper from 'swiper';
import {Pagination} from 'swiper/modules';
import 'swiper/swiper.css';
import 'swiper/modules/pagination.css';

let clickedService = '';
let isClickedToCard = false;

document.addEventListener('DOMContentLoaded', () => {
    const swiper = new Swiper('.slider', {
        loop: false,
        pagination: {
            enabled: true,
            el: '.swiper-pagination',
            type: 'bullets'
        },
        modules: [Pagination]
    });

    const cards = document.querySelectorAll(".card")

    cards.forEach((card, index) => {
        card.addEventListener("click", () => {
            const el = document.querySelectorAll(".services-list .service")[index];
            if (el) {
                isClickedToCard = true;
                el.scrollIntoView({behavior: "smooth"});
            }
        })
    })

    document.querySelector('.slider-left-arrow').addEventListener('click', () => {
        if (!swiper.isBeginning) {
            swiper.slidePrev()
        } else {
            swiper.slideTo(6)
        }
    })
    document.querySelector('.slider-right-arrow').addEventListener('click', () => {
        if (!swiper.isEnd) {
            swiper.slideNext()
        } else {
            swiper.slideTo(0)
        }
    })

    const dialog = document.querySelector('.call-banner-dialog')
    dialog.addEventListener('click', (e) => {
        if (e.target === dialog) {
            closeDialog()
        }
    })

    function handleKeyUp(e) {
        if (e.key === 'Escape') {
            closeDialog()
            closePreviewDialog()
        }
    }

    document.addEventListener('keyup', handleKeyUp)

    document.querySelector('.call-banner .dialog-close').addEventListener('click', closeDialog)

    document.querySelectorAll('[data-action="show-dialog"]').forEach(action => {
        action.addEventListener('click', (e) => {
            showDialog(action.getAttribute('data-service') || null)
        })
    });

    function closeDialog() {
        if (dialog.classList.contains('hidden')) {
            return
        }

        clickedService = null;

        dialog.classList.add('hidden');
    }

    function showDialog(service) {
        successBlock.style.display = 'none'
        if (dialog.classList.contains('hidden')) {
            dialog.classList.remove('hidden');
        }

        dialog.querySelector('input').focus()

        clickedService = service;
    }

    const previewDialog = document.querySelector('.demo-popup-dialog')
    previewDialog.addEventListener('click', (e) => {
        if (e.target === previewDialog) {
            closePreviewDialog()
        }
    })

    document.querySelector('.demo-popup-dialog .dialog-close').addEventListener('click', closePreviewDialog)

    document.querySelectorAll('[data-action="show-preview-popup"]').forEach(action => {
        action.addEventListener('click', (e) => {
            showPreviewDialog()
        })
    });

    function closePreviewDialog() {
        if (previewDialog.classList.contains('hidden')) {
            return
        }

        previewDialog.classList.add('hidden');
    }

    function showPreviewDialog() {
        if (previewDialog.classList.contains('hidden')) {
            previewDialog.classList.remove('hidden');
        }
    }

    const scrollToTopBtn = document.querySelector('.scroll-to-top')

    scrollToTopBtn.addEventListener("click", () => {
        const el = document.querySelector(".services-cards");
        if (isClickedToCard && el) {
            isClickedToCard = false;
            el.scrollIntoView({behavior: "smooth"});
        } else {
            document.querySelector('body').scrollIntoView({behavior: "smooth"});
        }
    })

    function handleScroll() {
        const scrollTop = window.scrollY;

        if (scrollTop > window.innerHeight && scrollToTopBtn.classList.contains('hidden')) {
            scrollToTopBtn.classList.remove('hidden');
        } else if (scrollTop < window.innerHeight && !scrollToTopBtn.classList.contains('hidden')) {
            scrollToTopBtn.classList.add('hidden');
        }
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll);
})


const element = document.getElementById('phone');
const element2 = document.getElementById('phone-mobile');
const maskOptions = {
    mask: '+{7} (000) 000 00-00'
};
const mask = IMask(element, maskOptions);
const maskButton = document.getElementById('call-banner-submit');
const dialogCheckbox = document.getElementById('call-banner-dialog-checkbox');
const successBlock = document.getElementById('call-banner-success');

const maskMobile = IMask(element2, maskOptions);
const maskButtonMobile = document.getElementById('mobile-call-banner-submit');
const checkbox = document.getElementById('call-banner-checkbox');
const successBlockMobile = document.getElementById('call-banner-success-mobile');

function bindValidator(mask, checkbox, buttonElement) {
    return () => {
        buttonElement.disabled = mask.unmaskedValue.length !== 11 || !checkbox.checked;
    }
}

function registerButtonOnClickAction(maskInput, successBlock) {
    let isBlocked = false;
    return () => {
        if (isBlocked) {
            return 0
        }

        isBlocked = true;

        const formData = new FormData()

        formData.append('phone', maskInput.value)
        formData.append('agreement', 'on')
        formData.append('service', clickedService || 'видеонаблюдение')
        formData.append('token', '<?= $token ?>')

        fetch('https://online-dozor.ru/mailSender.php', {
            method: 'POST',
            body: formData
        }).finally(() => {
            successBlock.style.display = 'flex'
            isBlocked = false;
        })
    }
}

const validateForm = bindValidator(maskMobile, checkbox, maskButtonMobile)
const validateDialogForm = bindValidator(mask, dialogCheckbox, maskButton)

maskMobile.on('accept', validateForm)
checkbox.addEventListener('change', validateForm)

mask.on('accept', validateDialogForm)
dialogCheckbox.addEventListener('change', validateDialogForm)

maskButton.addEventListener('click', registerButtonOnClickAction(mask, successBlock));
maskButtonMobile.addEventListener('click', registerButtonOnClickAction(maskMobile, successBlockMobile));
